import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {AuthService} from '../../services/auth/auth.service';
import {ConstantsProviders} from '../../services/constants';


declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
  public menuItems: any[];

  constructor(private router: Router,
              private route: ActivatedRoute, public translate: TranslateService, public _auth: AuthService) {
  }

  ngOnInit() {
    $.getScript('./assets/js/app-sidebar.js');
    // this.switchMenu();
    this.CrearMenuSegunPerfil();
  }

  CrearMenuSegunPerfil() {
    const TMPROUTES: RouteInfo[] = [];
    // const idClienteNotMenu = '312';
    // console.log('profile', JSON.stringify(this._auth.usuarioDTO));
    if (  this._auth.usuarioDTO.role.id === 1) {
      for (const menu of ROUTES) {
        // console.log('profile', menu.idpm);
        if ( menu.idpm.trim().toLocaleLowerCase() !== 'telephony'
          && menu.idpm.trim().toLocaleLowerCase() !== 'favorite') {
          // console.log('profile add', menu.idpm);
          TMPROUTES.push(menu);
        }
      }
      this.menuItems = TMPROUTES.filter(menuItem => menuItem);
    } else {
      // this.menuItems = ROUTES.filter(menuItem => menuItem);
      for (const menu of ROUTES) {
        if ( menu.idpm.trim() === 'admingx' && this._auth.usuarioDTO.role.id === 4) {
          TMPROUTES.push(menu);
        } else if ( menu.idpm.trim() !== 'admingx') {
          // console.log('clientid', this._auth.usuarioDTO.client.clienteId);
          // console.log('clientid', menu.idpm.trim().toLocaleLowerCase())
          // este parche horrible es para que en un cliente expesifico no le muestre
          // el historial de viajes
          if (this._auth.usuarioDTO.client.clienteId === ConstantsProviders.IDCLIENTE7CIELO &&
            menu.idpm.trim().toLocaleLowerCase() !== 'myservice' ||
            this._auth.usuarioDTO.client.clienteId === ConstantsProviders.IDCLIENTEHOTELGOES &&
            menu.idpm.trim().toLocaleLowerCase() !== 'myservice') {
            // console.log('clientid', 'add menu: ' + menu.idpm.trim().toLocaleLowerCase());
            TMPROUTES.push(menu);
          } else  if (this._auth.usuarioDTO.client.clienteId !== ConstantsProviders.IDCLIENTE7CIELO
          || this._auth.usuarioDTO.client.clienteId !== ConstantsProviders.IDCLIENTEHOTELGOES) {
            // console.log('clientid', 'else : ' + menu.idpm.trim().toLocaleLowerCase());
            TMPROUTES.push(menu);
          }
        }
      }
      this.menuItems = TMPROUTES.filter(menuItem => menuItem);
    }
  }
  switchMenu() {
    this.menuItems = [];
    const role: string = this._auth.usuarioDTO.role;
    const permisos = this._auth.usuarioDTO.programs_dispatch.programname;
    if ( role.trim().toUpperCase() === 'FLEET') {
      this.menuItems = ROUTES.filter(menuItem => menuItem);
    } else {
      switch (role) {
        case 'OwnerRemisTel': {
          this.crearMenu(permisos);
          // console.log('PROPIETARIO');
          break;
        }
        case 'Operator': {
          this.crearMenu(permisos);
          // console.log('Operador');
          break;
        }
      }
    }

  }

  crearMenu(permisos: any) {
    const TMPROUTES: RouteInfo[] = [];
    let dash = false;

    // busco primero dashboard
    for ( const pp of permisos) {
      if (pp.trim().toUpperCase() === 'DASHBOARD') {
        for (const m of ROUTES) {
          if (m.title.trim().toUpperCase() === 'DASHBOARD') {
            TMPROUTES.push(m);
            dash = true;
            break;
          }
        }
      }
      if (dash) {
        break;
      }
    }

    for ( const pp of permisos) {
      // console.log('1 Permiso', pp);
      for (const m of ROUTES) {
        // console.log('Routes', m);
        if ( pp.trim().toUpperCase() === m.idpm.trim().toUpperCase() && m.title.trim().toUpperCase() !== 'DASHBOARD') {
          TMPROUTES.push(m);
          break;
        }
      }
    }

    // console.log('TMPROUTERS', JSON.stringify(TMPROUTES));
    for ( const tsm of TMPROUTES ) {
      let existe = false;
      if ( tsm.submenu.length > 0 ) {
        existe = false;
        // console.log('00 Largo SUBM', tsm.submenu.length);
        // console.log('00 SUBM', JSON.stringify(tsm.submenu));
          for ( const sm of tsm.submenu ) {
            // console.log('01 SM Title', sm.title + ' idpm ' + sm.idpm);

            for ( const tpp of permisos ) {
              existe = false;
              // console.log('02 SMP', sm.idpm + ' TPermiso: ' + tpp);
              if ( sm.idpm.trim().toLowerCase() === tpp.toString().trim().toLowerCase()) {
                // console.log('03 SMP IDPM', sm.idpm + ' Permiso: ' + tpp);
                existe = true;
                break;
              }
            }
            // eliminar el submenu
            if (!existe) {
              // console.log('SMenuEliminar: ', sm.idpm + ' ' + sm.title);
              const index: number = tsm.submenu.indexOf(sm);
              if (index !== -1) {
                tsm.submenu.splice(index, 1);
              }
            }
          }
        // console.log('Como Largo Quedo: ', tsm.submenu.length);
        //   console.log('Como Quedo: ', JSON.stringify(tsm.submenu));
      }
    }

    if ( TMPROUTES.length > 0) {
      this.menuItems = TMPROUTES.filter(menuItem => menuItem);
    }
  }
  // NGX Wizard - skip url change
  ngxWizardFunction(path: string) {
    if (path.indexOf('forms/ngx') !== -1)
      this.router.navigate(['forms/ngx/wizard'], { skipLocationChange: false });
  }
}
