import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardGuard implements CanActivate {

  constructor( public _auth: AuthService, public router: Router) {

  }
  canActivate() {
    // console.log('Paso por el LoginGuard');

    if ( this._auth.estaLogueado()) {
      // console.log('Paso el GuardLogin');
      return true;
    } else {
      console.log('Bloquedado por el GuardLogin');
      this.router.navigate(['/pages/login']);
      return false;
    }
  }
}
