<!--Footer Starts-->
<footer>
    <div class="container-fluid">
        <p class="copyright text-center">
          Copyright  &copy;  {{currentDate | date: 'yyyy'}} <a id="celeritas" href="https://www.celeritas.uy/">CELERITAS S.A</a>, All rights reserved.
        </p>

    </div>
</footer>
<!--Footer Ends-->
