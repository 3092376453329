export class ConstantsProviders {

  public static PROTOCOL = 'http://';
  public static PORT_WS = ':80';
  public static PROTOCOLHTTPS = 'https://';
  public static PORT_WSHTTPS = ':443';

  // public static SERVER_URL = 'devceleritas.als.com.uy'; // srv desarrollo
  public static SERVER_URL = 'webcore.celeritas.uy';

  public static SERVER_WS: string = ConstantsProviders.PROTOCOLHTTPS + ConstantsProviders.SERVER_URL + ConstantsProviders.PORT_WSHTTPS;
  // public static SERVER_WS: string = ConstantsProviders.PROTOCOL + ConstantsProviders.SERVER_URL + ConstantsProviders.PORT_WS; // dev
  public static APPGATEWAY = '/gotaxi-gateway-web/webresources/';

  public static URIFOTOCHOFER = 'https://www.fleet.uy/fotoschoferceleritas/';
  public static EXTFILECHOFER = '.jpg';

  // WS Gateway
  public static BASEWS = 'clientwebfleet/';
  public static WSGETUSER = 'getcurrentuser/';
  public static WSGETPROFILE = 'getprofile/';
  public static WSCHANGEPASSWORD = 'changepassword/';
  public static WSGETCLIENT = ConstantsProviders.BASEWS + 'getClient/';
  public static WSNEWSERVICE = ConstantsProviders.BASEWS + 'putservice/';
  public static WSFINDSERVICE = ConstantsProviders.BASEWS + 'getFindServices/';
  public static WSGETCATEGORYVEHICLE = 'clientfx/getvehicleandcategoryfx/';
  public static WSGETSERVICESMONITOR = ConstantsProviders.BASEWS + 'getallservicesmonitor/';
  public static WSGETALLVEHICLE = ConstantsProviders.BASEWS + 'getfleet';
  public static WSSENDMESSAGE = ConstantsProviders.BASEWS + 'sendMsg/';
  public static WSGETTRAKING = ConstantsProviders.BASEWS + 'trackingvehicle/';
  public static WSGETTRAKINGSERVICE = ConstantsProviders.BASEWS + 'trackingservice/';
  public static WSGETALLVEHICLEONLINE = ConstantsProviders.BASEWS + 'getVehicleOnLine';
  public static WSGLOBAL = ConstantsProviders.BASEWS + 'getglobal/';
  public static WSTYPEPAY = 'gestion/getAllTypePayment/'; // es el ws en despacho relacionado a la admin
  public static WSSEARCHSERVICEHIST = ConstantsProviders.BASEWS + 'getservicehistoryclient/';
  public static WSCANCELSERVICE = ConstantsProviders.BASEWS + 'cancel_service/';
  public static WSGETALLUSER = ConstantsProviders.BASEWS + 'getalluser/';
  public static WSGETSUBACCOUNTUSER = ConstantsProviders.BASEWS + 'getsubaccountuser/';

  // nro de cliente Motel
  // public static IDCLIENTE7CIELO = '312'; // dev
  public static IDCLIENTE7CIELO = '9005';
  public static IDCLIENTEHOTELGOES = '10047';
}
