<div class="wrapper" [dir]="options.direction">
    <div class="app-sidebar" data-active-color="white" data-background-color="black" data-image="assets/img/sidebar-bg/02.jpg">
        <app-sidebar></app-sidebar>
        <div class="sidebar-background"></div>
    </div>
    <app-navbar></app-navbar>
    <div class="main-panel">
        <div class="main-content">
            <div class="content-wrapper">
                <div class="container-fluid">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
    <app-notification-sidebar></app-notification-sidebar>
    <!--<app-customizer (directionEvent)="getOptions($event)"></app-customizer>-->
</div>
