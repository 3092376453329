import { RouteInfo } from './sidebar.metadata';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  // { path: '/dashboard/dashboard', title: 'Dashboard', icon: 'fa fa-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], idpm: 'dash' },
  // { path: '/clientes/lclientes', title: 'Customers', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/pages/servicios', title: 'Monitoreo', icon: 'fa fa-desktop', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], idpm: 'managment' },
  { path: '/pages/servicio', title: 'Service', icon: 'fa fa-car', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], idpm: 'telephony' },
  { path: '/pages/myservice', title: 'Tus Viajes', icon: 'fa fa-history', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], idpm: 'myservice' },
  // { path: '/pages/favorite', title: 'Favoritos', icon: 'fa fa-star', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], idpm: 'favorite' },
  // { path: '/pages/calculator', title: 'Calcular', icon: 'fa fa-calculator', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], idpm: 'calc' },
  { path: 'http://webadmin.celeritas.uy/gotaxi-online/', title: 'Administración', icon: 'fa fa-archive', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [], idpm: 'admingx' },
  // { path: '/pages/tracking', title: 'Seguimiento', icon: 'fa fa-map-marker', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], idpm: 'telephony' },
  // {
  //   path: '', title: 'Find', icon: 'fa fa-binoculars', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, idpm: 'mngfind',
  //   submenu: [
  //     { path: '/uikit/grids', title: 'FindService', icon: 'fa fa-search', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], idpm: 'findservice' },
  //     { path: '/uikit/typography', title: 'FindScheduler', icon: 'fa fa-clock-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], idpm: 'findschedule' },
  //   ]
  // },
  // {
  //   path: '', title: 'Fleet', icon: 'fa fa-tachometer', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, idpm: 'mngfleet',
  //   submenu: [
  //     { path: '/uikit/grids', title: 'CtrlFleet', icon: 'fa fa-search', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], idpm: 'ctrlflota' },
  //     { path: '/uikit/typography', title: 'MsgFleet', icon: 'fa fa-clock-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], idpm: 'msgflota' },
  //     { path: '/uikit/typography', title: 'DriverLogin', icon: 'fa fa-clock-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], idpm: 'driverlogin' },
  //   ]
  // },
];
